.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.content {
  display: flex;
  justify-content: center;
  width: 100%;

  .fields {
    margin-left: 50px;
    width: 100%;
  }
}

.buttons {
  display: flex;
  margin-top: 50px;
  margin-bottom: auto;

  &Item {
    padding: 15px 60px;

    &:nth-child(n+1) {
      margin-left: 60px;
    }
  }
}