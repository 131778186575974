.navigation {
  display: flex;
  flex-direction: column;
  align-items: center;

  .help {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    &Item {
      display: flex;
      align-items: center;

      &:nth-child(n+2) {
        margin-left: 40px;
      }

      &Title {
        margin-right: 20px;
      }
    }
  }
}