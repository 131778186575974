@import "../../../../../../core/scss/vars";

.titleWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 40px;
  justify-content: center;

  .title {
    text-align: center;
  }

  .line {
    background-color: $white;
    max-width: 300px;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    margin: 20px auto 30px;
  }
}

.bottomBar {
  left: 0;
  position: fixed;
  width: 100%;
  bottom: 10px;

  z-index: -1;
  opacity: 0;
  transition: all .3s;

  &This {
    margin: 0 auto;
    width: fit-content;
  }
}

.active {
  z-index: 1;
  opacity: 1;
}