@import "../../../../../../../../../../../../../core/scss/vars";

.progress {
  width: 100%;
}

.progressBar {
  //min-width: 250px;
  width: 100%;
  position: relative;
  height: 7px;

  &All,
  &Complete {
    height: 100%;
    border-radius: 100px;
  }

  &All {
    width: 100%;
    background-color: $gray-1;
  }
  &Complete {
    position: absolute;
    top: 0;
    background-color: $purple;
  }
}

.progressBarText {
  margin-top: 2px;
  display: flex;
  justify-content: center;
}