@import "../../../../../../../core/scss/vars";

.block {
  position: relative;
  height: 100%;

  .placeholder {
    position: absolute;
  }

  .hidePlaceholder {
    display: none;
  }
}

.editor {
  color: $white;
  font-family: $f-book;
  font-size: $fs-20;
  cursor: text;
  min-height: 100%;
  padding: 10px 10px 250px;

  h1 {
    font-size: $fs-40;
    margin-top: 32px;
    margin-bottom: 4px;
  }

  h2 {
    font-size: $fs-32;
    margin-top: 28px;
    margin-bottom: 2px;
  }

  h3 {
    font-size: $fs-24;
    margin-top: 28px;
    margin-bottom: 2px;
  }

  img {
    width: 100% !important;
    height: 100% !important;
    border-radius: 20px;
    //margin-top: 40px;
    //margin-bottom: 3px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
    margin-top: 30px;
    margin-bottom: 3px;
  }

  p {
    margin-top: 20px;
    margin-bottom: 1px;
  }
}