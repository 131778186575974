@import "../../../../../../../../../../core/scss/vars";


.content {
  display: flex;
  background-color: $purple;
  border-radius: 10px;

  .title {
    padding: 11px 37px;
  }
}