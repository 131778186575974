@import "../../../../../../../../../../../../../../../../core/scss/vars";

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  transition: all .2s;
  border: 3px solid $empty;

  .create {
    width: 18px;
    height: 18px;
  }
}

.active {
  border-color: $white;
}