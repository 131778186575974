@import "../../../../../../../core/scss/vars";

.block {
  display: flex;
  align-items: center;
}

.number {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid $purple-2;
  border-radius: 100px;
  min-width: 80px;
  height: 80px;
}

.description {
  margin-left: 40px;
}