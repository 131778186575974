@import "../../../../../../../../../core/scss/vars";

.collection {
  position: relative;
  display: flex;
  padding: 5px;

  &Image {
    width: 33px;
    height: 33px;
    border-radius: 5px;
    object-fit: cover;
    transition: all .3s;
  }

  &Title {
    margin: auto auto auto 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $gray-2 !important;

    transition: all .15s;
  }

  &:hover {
    .collectionTitle {
      color: $white !important;
    }
  }
}