@import "../../../../../../scss/vars";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  display: flex;
  background-color: $black_50;
  width: 100vw;
  height: 100vh;
}

.block {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $black-light-2;
  border-radius: 50px;
  padding: 60px 70px 70px 70px;
  margin: auto;
  max-width: 1000px;
  //width: 100%;
  max-height: 850px;

  .title {
    &Text {
      text-align: center;
      margin: 0 50px;
    }
    &Cross {
      width: 25px;
      height: 25px;

      position: absolute;
      right: 70px;
      top: 70px;
      transition: all .15s;

      &:hover {
        scale: 1.2;
      }
    }
  }

  .content {
    width: 100%;
  }
}