@import "../../../../scss/vars";

.wrapper {
  width: 487px;
  max-height: 605px;
  //padding: 30px 45px 25px;
  padding: 0 !important;
  padding-top: 30px !important;
  background-color: $black-light-1;
}
.titleWrapper {
  font-size: 24px !important;
}
.cross {
  top: 36px !important;
  right: 55px !important;
  width: 22px !important;
  height: 22px !important;
}


.content {
  padding: 0 45px;

  .search {
    border-radius: 50px;
    margin-top: 25px;
  }

  .collections {
    overflow-y: auto;
    margin-top: 12px;
    min-height: 212px;
    max-height: 350px;

    .empty {
      margin: 20px 0;

      .symbol {
        color: $green;
      }

      .titleEmpty {
        margin-top: 10px;
        text-align: center;
      }
    }

    .collectionItem:nth-child(n+2) {
      margin-top: 10px;
    }
  }
}

.navigation {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  box-shadow: 0px -1px 4px $black_50;
  padding: 25px;

  &Button {
    padding: 17px 64px;
  }
}