.block {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: 870px;

  .item:nth-child(n+2) {
    margin-top: 25px;
  }
}