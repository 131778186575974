.block {
  position: relative;
  min-height: 300px;
  max-width: 350px;
}

.card {
  padding: 18px;

  .image {
    width: 100%;
    height: 222px;
    object-fit: cover;
    border-radius: 20px;
    margin: 0 auto;
  }

  .title {
    margin-top: 10px;
    margin-bottom: 5px;
    overflow-wrap: break-word
  }
}