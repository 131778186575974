@import "../../../../../core/scss/vars";

.main {
  position: relative;

  .wallpaper {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;

    &Image {
      position: absolute;
      height: 100%;
      width: 100%;
      //left: -20%;
      object-fit: cover;
    }
    &Dark {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      background-color: $black_50;
    }
  }

  .content {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;

    &Author {
      //margin-top: auto;
    }

    &Author, &Title {
      text-align: center;
      max-width: 1200px;
    }

    &Navigations {
      display: flex;
      margin-top: 80px;

      &Button:nth-child(n+2) {
        margin-left: 25px;
      }
    }

    &Info {
      display: flex;
      position: absolute;
      bottom: 20px;

      &Item:nth-child(n+2) {
          margin-left: 20px;
      }
    }
  }
}