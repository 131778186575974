.card {
  justify-content: center;
  align-items: center;

  &:hover {
    .image {
      margin-bottom: 20px;
    }
    .title {
      z-index: 0;
      opacity: 1;
    }
  }

  .image {
    width: 50px;
    height: 50px;
    transition: all .2s;
  }

  .title {
    position: absolute;
    z-index: -1;
    opacity: 0;
    top: calc(50% + 30px);
    bottom: auto;
    transition: all .2s;
  }
}