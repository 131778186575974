@import '../../../scss/vars';

.button {
  width: 43px;
  height: 43px;
  border-radius: 43px;
  background-color: $black-light-3;

  //outline: none;
  border: 0;
  
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all .15s;

  &:hover {
    background-color: $black-light-4;
  }

  .image {
    width: 18px;
    height: 18px;
  }
}