@import "../../../../scss/vars";


.user {
  margin: auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  border: 2px solid $empty;
  background-color: $black-light-3;
  padding: 20px 45px 30px;
  transition: all .2s;

  &:hover {
    //background-color: $dark-green;
    border-color: $green;
  }

  .avatar {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 50%;
  }

  .username {
    margin-top: 10px;
  }

  .status {
    margin-top: 5px;
    color: $green;
  }

  .path {
    margin-top: 5px;
    color: $gray-2;
  }

  .button {
    margin-top: 10px;
    padding: 9px 30px;
  }

  .active {
    background-color: $black_20;

    &:hover {
      background-color: $black_20;
    }
  }
}