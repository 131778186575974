@import "../../../../../../scss/vars";

.course {
  position: relative;
  width: 310px;
  //min-width: 290px;
  height: 300px;

  .titleInfo {
    position: relative;
    display: flex;
    //justify-content: center;

    width: 100%;
    max-height: 200px;
    //height: 100%;
    height: 200px;

    &Image {
      position: absolute;
      width: 100%;
      height: 200px;

      &Self {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
        border-radius: 20px;
      }

      &Dark {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-color: $black_70;
        border-radius: 20px;
      }
    }

    &Text {
      z-index: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 0 20px;

      &Title {
        margin: auto;
        text-align: center;
      }

      &Author {
        position: absolute;
        bottom: 0;
        margin-bottom: 20px;
      }
    }
  }

  .info {
    padding: 20px;

    .price {
      margin-top: 0 !important;
    }
  }
}