.menu {
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  margin: 0 100px 100px;
  height: 100%;
}