@import "../../../../../../../../scss/vars";

.button {
  background-color: $green;
  border-color: $green;

  &:hover {
    background-color: $green-2;
    border-color: $green-2;
  }
}