@import "../../../../../../../../../../core/scss/vars";
@import "../../../../../../../../../../core/scss/mixins/fluid-font-size";
@import "../../../../../../../../../../core/scss/mixins/media";

.wrapper {
  display: flex;

  .image {
    width: 222px;
    height: 222px;
    border-radius: 20px;
    object-fit: cover;
  }

  .text {
    width: 100%;
    margin-left: 55px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      overflow-wrap: anywhere;
      @include fluidFontSize($fs-20, $fs-64, $sm-mobile-width, 2000px, $fs-32);
    }

    .description {
      margin-top: 5px;
      color: $gray-2;
    }
  }
}