
.wrapper {
  display: flex;
  flex-direction: column;

  &:nth-child(n+2) {
    margin-top: 25px;
  }
  .input {
    margin-top: 5px;
  }
}