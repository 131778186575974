.block {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
//
//.wrapper {
//
//}

.list {
  margin-top: 45px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-gap: 20px;
}