@import "../../../../../../../../../core/scss/vars";

.card {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: $black-light-3;
  border: 2px solid $black-light-3;
  border-radius: 20px;
  min-height: 300px;
  max-width: 350px;
  height: 100%;
  width: 100%;
  transition: all .2s;
}