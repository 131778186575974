@import "../../../core/scss/vars";
@import "../../../core/scss/mixins/media";

.block {
  background-color: $black-light-2;
  max-width: 700px;
  width: 100%;
  border-radius: 35px;

  display: flex;
  flex-direction: column;

  padding: 70px 65px 100px;
}

.title {
  text-align: center;
}

.message {
  text-align: center;
  margin-top: 5px;
  font-size: $fs-18;
  font-family: $f-bold;

  &Error {
    color: $red;
  }

  &Success {
    color: $green;
  }
}

.listFields {
  margin-top: 60px;
}

.submit {
  display: flex;
  width: 100%;
  margin-top: 35px;
  justify-content: center;
}

.navDescription {
  margin: 10px auto auto;

  &__link {
    margin-left: 5px;
  }
}


/* Small mobile devices | 480px or less */
@include sm-mobile {
  .block {
    padding-left: 20px;
    padding-right: 20px;
  }
}