@import "../../../../scss/vars";

.block {
  &:nth-child(n+2) {
    margin-top: 45px;
  }

  .courses {
    margin-top: 45px;
  }

}