@import "../../../../scss/vars";

.collection {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: $black-light-3;
  border-radius: 20px;
  padding: 20px 30px 30px;
  border: 2px solid $black-light-3;
  transition: all .2s;
  width: 100%;

  &:hover {
    background-color: $dark-orange;
    border-color: $orange;

    .preview {
      border-color: $orange;
    }
  }

  .preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    border: 2px solid $black-light-2;
    transition: all .2s;
  }

  .text {
    display: flex;
    flex-direction: column;

    .title {
      margin-top: 9px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .status {
      margin: 5px auto 0;
      text-align: center;
    }

    .author {
      color: $gray-2;
      margin: 5px auto 0;
      text-align: center;
    }
  }

  .rating {
    position: absolute;
    bottom: 3px;
    right: 10px;
  }
}