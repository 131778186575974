@import "../core/scss/vars";

.page {
  background-color: $black-light-1;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 70px;
    .title {
      font-size: 100px;
    }
  }
}