@import "../../../scss/vars";

.link {
  display: flex;

  &Title {
    color: $gray-2 !important;
    border-bottom: 2px solid $empty;
    transition: all .15s;

    &:hover {
      border-color: $gray-2;
    }
  }
}