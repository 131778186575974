@import "../../../../../scss/vars";

.course {
  display: flex;
  flex-direction: column;
  background-color: $black-light-3;
  border-radius: 20px;

  .author {
    text-align: center;
  }

  .title {
    text-align: center;
  }

  .mainInfo {
    display: flex;
    justify-content: center;
  }

  .otherInfo {
    display: flex;
    justify-content: center;
    margin-top: 14px;



    .price {
      text-align: center;
      margin: 15px auto auto;
    }
  }
}