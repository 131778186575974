@import "../../../../../../../core/scss/vars";

.block {
  position: relative;
  height: 100%;

  .placeholder {
    position: absolute;
  }

  .hidePlaceholder {
    display: none;
  }
}

.editor {
  color: $white;
  font-family: $f-book;
  font-size: $fs-20;
  cursor: text;
  min-height: 100%;
  padding: 10px 10px 250px;

  h1 {
    font-size: $fs-40;
    margin-top: 32px;
    margin-bottom: 4px;
  }

  h2 {
    font-size: $fs-32;
    margin-top: 28px;
    margin-bottom: 2px;
  }

  h3 {
    font-size: $fs-24;
    margin-top: 28px;
    margin-bottom: 2px;
  }
}