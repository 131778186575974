@import "../../../../../../../../scss/vars";

.button {
  background-color: $black-light-3;
  border-color: $black-light-3;

  &:hover {
    background-color: $black-light-4;
    border-color: $black-light-4;
  }
}