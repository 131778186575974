$elg-desktop-width: 1440px;
$lg-desktop-width: 1200px;
$md-desktop-width: 993px;
$sm-tablets-width: 768px;
$sm-mobile-width: 480px;
$extra-sm-mobile-width: 320px;

/*==========  Desktop First  ==========*/

/* Extra large devices | 1440px or less */
@mixin elg-desktop {
  @media only screen and (max-width: #{$elg-desktop-width}) {
    @content
  }
}

/* Large devices | 1300px or less */
@mixin lg-desktop {
  @media only screen and (max-width: #{$lg-desktop-width}) {
    @content
  }
}

/* Medium devices | 993px or less */
@mixin md-desktop {
  @media only screen and (max-width: #{$md-desktop-width}) {
    @content
  }
}

/* Small tablet devices | 768px or less */
@mixin sm-tablets {
  @media only screen and (max-width: #{$sm-tablets-width}) {
    @content
  }
}

/* Small mobile devices | 480px or less */
@mixin sm-mobile {
  @media only screen and (max-width: #{$sm-mobile-width}) {
    @content
  }
}

/* Extra small mobile devices | 320px or less */
@mixin esm-mobile {
  @media only screen and (max-width: #{$extra-sm-mobile-width}) {
    @content
  }
}
