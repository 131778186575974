@import "../../../scss/vars";

.wrapper {
  display: flex;
  flex-direction: column;
}

.input {
  position: absolute;
  z-index: 2;
  opacity: 0;
}

.imageWrapper {
  width: 100%;
  position: relative;
  margin: 10px auto 0;

  &:hover {
    .dark {
      opacity: 1;
      z-index: 1;
    }
  }

  .dark {
    position: absolute;
    background-color: $black_30;
    z-index: -1;
    opacity: 0;
    transition: all .3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .editButton {
      background-color: $black_50;
      padding: 20px;
      border-radius: 70px;

      &Image {
        width: 40px;
        height: 40px;
      }
    }

    &Text {
      margin-top: 5px;
      text-align: center;
    }
  }

  .image {
    object-fit: cover;
  }
}

.help {
  color: $gray-2;
  margin-top: 5px;
  text-align: center;
}

.default {
  position: relative;
  margin-top: 10px;
  display: flex;
  background-color: $purple_20;
  padding: 20px 0;
  border-radius: 30px;
  cursor: pointer;
  transition: all .3s;

  &Input {
    top: 0;
    width: 100%;
    height: 100%;
  }

  &Text {
    text-align: center;
  }

  &:hover {
    padding: 40px 0;
    background-color: $purple_40;
  }
}