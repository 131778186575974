@import "../../../../../../../../../../core/scss/vars";

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}