@import "../../../../scss/vars";


.course {
  position: relative;

  .previewWrapper {
    overflow-y: hidden;
  }

  .preview {
    background-color: $black-light-3;
  }

  .activeWrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -35px;

    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0;
    z-index: -1;
    transition: all .3s ease-in-out;

    .blur {
      position: absolute;
      z-index: -1;
      border-radius: 20px;
      background: $blue-pink-gradient;
      width: 310px;
      height: 100%;

      transition: all .3s;
    }

    .active {
      box-shadow: 0 0 8px 5px $black_25;

      transition: all .3s;
    }
  }

  &:hover .activeWrapper {
    z-index: 5;
    opacity: 1;
  }
}