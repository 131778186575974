@import "../../../../../../scss/vars";

.course {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px;
  width: 380px;

  .author {
    color: $red;
    margin-top: 20px;
  }

  .description {
    text-align: center;
    margin-top: 10px;
  }

  .mainInfo {
    margin-top: 15px;
  }

  .otherInfo {
    margin-bottom: 20px;
  }
}

.empty {
  .description:after {
    content: 'Нет описания';
    color: $gray-2;
    font-family: $f-medium !important;
  }
}

.price {
  margin-top: 0 !important;
}