@import "../../../../../../../../../../core/scss/vars";

.toolbar {
  position: absolute;
  z-index: 9999;
  //height: 35px;
  background-color: $black-light-4;
  padding: 5px;
  border-radius: 10px;
  cursor: default;
  left: 0;

  .list {
    display: flex;

    .item {
      display: flex;
      border-radius: 10px;
      color: $white;
      padding: 5px 10px;
      transition: all .2s;
      border: 2px solid $empty;

      &:hover {
        background-color: $black-light-5;
        border-color: $empty;
      }

      &:nth-child(n+2) {
        margin-left: 2px;
      }

      &Active {
        border-color: $purple !important;
        //background-color: $purple;
      }
    }
  }
}