@import "../../../scss/vars";

.author {
  display: flex;

  .wrapper {
    display: flex;

    .image {
      width: 28px;
      //height: 22px;
      object-fit: cover;
      border-radius: 20px;
      border: 2px solid $empty;

      transition: all .3s;
    }

    .name {
      color: $gray-1;
      margin: auto 0 auto 5px;

      transition: all .15s;
    }

    &:hover {
      .image {
        border-color: $green;
      }

      .name {
        color: white;
      }
    }
  }
}