@import '../../../../core/scss/vars';
@import '../../../../core/scss/mixins/media';

.page {
  display: flex;
  position: relative;
}

.main {
  display: flex;
  background-color: $black-light-1;
  //position: absolute;
  width: 100%;
  height: 100vh;

  &Wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: $black-light-1;
  }

  &Content {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: $black-light-2;
    height: 100%;
  }

  &ContentRadius {
    border-radius: 35px 0 0 0;
  }
}

.min {
  margin-left: 86px;
}

.big {
  margin-left: 300px;
}

/* Extra large devices | 1440px or less */
@include elg-desktop {
  .mainContent {
    margin-left: 86px;
  }
}