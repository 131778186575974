@import "../../../../../../../../../../../../../core/scss/vars";

.wrapper {
  position: absolute;
}

.kebab {
  position: relative;
  background: none;
  border: 0;

  .image {
    width: 30px;
    height: 30px;
    transition: .2s;
  }
}

.menu {
  position: absolute;
  top: 30px;
  right: -10px;
  z-index: -1;
  opacity: 0;
  transition: all .2s;
  box-shadow: 0 0 10px 0 $black_25;

}

.kebab:hover + .menu,
.menu:hover{
  z-index: 1;
  opacity: 1;
}