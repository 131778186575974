@import "../../../../../../../../core/scss/vars";

.block {
  padding: 20px 30px;
  background-color: $black_50;
  border-radius: 100px;
  display: flex;
  align-items: center;
}

.image {
  width: 40px;
  height: 40px;
}

.text {
  margin-left: 20px;
}