.block {
  display: flex;
}

.title {
  max-width: 360px;
  width: 100%;
}

.description {
  max-width: 700px;
  width: 100%;
  margin-left: 100px;
}