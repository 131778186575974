

.logo {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.content {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}