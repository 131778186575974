@import "../../scss/vars";


.select {
  position: relative;
  border-radius: 25px;
  background-color: $black-light-3;
  transition: all .15s;

  &Content {
    position: absolute;
    top: calc(100% + 10px);
    z-index: 8;
    box-shadow: 0 0 10px 0 $black_25;


    &Active {
      display: none !important;
    }
  }

}
