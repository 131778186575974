@import "../../../../../../scss/vars";

.collection {
  display: flex;

  .image {
    width: 85px;
    height: 85px;
    object-fit: cover;
    border-radius: 10px;
  }

  .info {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: 6px;
    margin-left: 20px;

    &Item:nth-child(n+2) {
      margin-left: 15px;
      margin-top: auto;
      margin-bottom: auto;
    }

    .top {
      display: flex;

      .title {
        border-bottom: 3px solid $empty;
        transition: all .15s;

        &:hover {
          border-bottom: 3px solid $white;
        }
      }

    }
  }
}