@import "../../../../core/scss/vars";

.button {
  font-family: $f-medium;
  font-size: $fs-16;
  color: $white;
  text-align: center;

  max-width: 320px;
  width: 100%;

  background-color: $purple;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 40px;
  border: none;

  transition: all .15s;

  &:hover {
    background-color: $purple-2;
  }
}