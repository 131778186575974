.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    text-align: center;
  }

  .imageWrapper {
    max-width: 300px;
  }

  .image {
    border-radius: 150px;
    width: 300px;
    height: 300px;
    //margin-top: 10px;
  }
}