@import "../../../../../../scss/vars";

.block {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover .active {
    z-index: 1;
    opacity: 1;
  }
}

.active {
  display: flex;
  justify-content: center;
  position: relative;
  opacity: 0;
  z-index: -1;

  cursor: default;
  transition: all .15s;

  &:hover {
    opacity: 0 !important;
    z-index: -1 !important;
  }

  .activeModal {
    top: -30px;
  }
}

.rating {
  display: flex;
  border-radius: 10px;
  cursor: pointer;
  margin: auto;

  .title {
    padding: 6px 10px;
    display: flex;
  }
}
