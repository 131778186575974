@import "../../../../../../../../core/scss/vars";


.block {
  display: flex;
  width: 100%;

  background-color: $black-light-1;
  padding: 25px 0;
}

.search {
  margin: auto;
}

.userNav {
  right: 30px;
  z-index: 9999;
}
