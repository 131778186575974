// Font style
$f-black: 'Gotham-Black', sans-serif;
$f-bold: 'Gotham-Bold', sans-serif;
$f-medium: 'Gotham-Medium', sans-serif;
$f-medium-italic: 'Gotham-MediumItalic', sans-serif;
$f-book: 'Gotham-Book', sans-serif;

// Font size
$fs-72: 72px;
$fs-64: 64px;
$fs-48: 48px;
$fs-40: 40px;
$fs-36: 36px;
$fs-32: 32px;
$fs-24: 24px;
$fs-20: 20px;
$fs-18: 18px;
$fs-16: 16px;
$fs-14: 14px;
$fs-13: 13px;
$fs-12: 12px;

// Color
$black-light-1: #1A1A1A;
$black-light-2: #1F1F1F;
$black-light-3: #282828;
$black-light-4: #404040;
$black-light-5: #666666;

$white: #FFF;
$gray-1: #BFBFBF;
$gray-2: #969696;

$empty: #00000000;

$purple: #7B61FF;
$purple-2: #593BF3;
$purple-2_70: rgba($purple-2, 0.7);
$purple_20: rgba($purple, 0.2);
$purple_40: rgba($purple, 0.4);

$red: #FF5252;
$red_20: rgba($red, 0.2);

$green: #2ED573;
$green-2: #19693B;
$green-3: #3AFF8C;
$green_20: rgba($green, 0.2);
$dark-green: #294B37;

$activity-1-5: #114828;
$activity-6-10: #19693B;
$activity-11-20: #2BBD69;
$activity-21: #3AFF8C;

$yellow: #FFC312;
$yellow-dark: #534724;
$yellow_20: rgba($yellow, 0.2);

$blue: #0097E6;
$blue-dark: #203E4E;
$blue_20: rgba($blue, 0.2);

$orange: #FB7904;
$orange_20: #rgba($orange, 0.2);
$dark-orange: #523821;

$black: #000;
$black_20: rgba($black, 0.2);
$black_25: rgba($black, 0.25);
$black_30: rgba($black, 0.3);
$black_50: rgba($black, 0.5);
$black_70: rgba($black, 0.7);

$purple-red-gradient: linear-gradient(90deg, $purple 0%, $red 100%);
$blue-pink-gradient: linear-gradient(-135deg, #396FFF 0%, #D45AFF 100%);