@import "../../../../../scss/vars";

.block {
  display: flex;
}

.navigation {
  justify-content: center;
  align-items: center;

  height: 252px;
  width: 220px;
}

.course {
  width: 100%;
  margin-left: 20px;
  margin-right: 50px;
}