@import "../../../../../../../../../core/scss/vars";


.block {
  position: relative;
  width: 100%;

  .field {
    display: flex;
    margin-top: 5px;
    width: 100%;

    .image {
      position: absolute;
      z-index: 1;
      width: 22px;
      height: 22px;
      margin-left: 16px;
      margin-top: 15px;
    }

    .input {
      width: 100%;
      border-radius: 15px;
      border: 2px solid $gray-1;
      background-color: $empty;
      padding: 15px 16px 15px 48px;
      outline: 0;

      font-size: $fs-18;
      font-family: $f-medium;
      color: $white;

      transition: all .2s;

      &:focus {
        border-color: $purple;
      }
    }

    &Error {
      border-color: $red !important;
    }
  }

  .description {
    margin-left: 5px;

    .text {
      color: $gray-2 !important;
      margin-top: 5px;
    }

    .error {
      display: flex;
      margin-top: 5px;

      &Text {
        color: $red !important;
        margin-left: 5px;
      }
    }
  }
}