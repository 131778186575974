@import "../../../../../../../../../core/scss/vars";

.button {
  background-color: $black-light-2;
  border-radius: 20px;
  padding: 28px;
  transition: all .15s;

  &:hover {
    background-color: $black-light-4;
  }

  .image {
    width: 26px;
    height: 26px;
  }
}