
.block {
  display: flex;
  flex-direction: column;
}

.title {
  text-align: center;
}

.courses {
  margin-top: 55px;
}