.veryBig {
  padding: 12px 34px;
}

.big {
  padding: 12px 24px;
}

.default {
  padding: 9px 19px;
}