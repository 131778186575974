.check {
  display: none;
}

.input {
  display: none;

  &:checked + .check {
    display: block;
    margin-left: 20px;
  }
}