@import "../../../../../../core/scss/vars";

.block {
  display: flex;
}

.back {
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    .imageWhite {
      opacity: 0;
    }
    .imagePurple {
      opacity: 1;
    }

    .title {
      color: $purple;
    }
  }
}

.image {
  width: 15px;
  height: 15px;
  transition: all .2s;

  &White {
    opacity: 1;
  }

  &Purple {
    position: absolute;
    opacity: 0;
  }
}

.title {
  margin-left: 10px;
  transition: all .2s;
}