@import "../../../scss/vars";

.menu {
  display: flex;
  justify-content: center;

}

.border {
  height: 100%;
  width: 68px;
}

.content {
  display: flex;
  background-color: $black-light-1;
  //height: 100%;
  padding-top: 10px;
  padding-bottom: 20px;
  //margin: auto 0;

  .button {
    display: flex;

    &:nth-child(n+2) {
      margin-left: 50px;
    }
  }
}