@import "../../../scss/vars";


.title {
  display: flex;
  align-items: center;
  border-radius: 25px;
  padding: 12px 20px 12px 25px;

  transition: all .15s;
  cursor: pointer;

  &:hover {
    background-color: $black-light-4;
  }

  .image {
    margin-left: 10px;
  }
}