@import "../../../../../../../../scss/vars";

.navigation {
  display: flex;
  flex-direction: column;

  .text {
    display: flex;
    flex-direction: column;

    &Item {
      margin: auto;
    }

    .purple {
      color: $purple;
    }
  }
  .button {
    margin-top: 20px;
  }

  .active {
    background-color: $black_30;

    &:hover {
      background-color: $black_30;
    }
  }
}