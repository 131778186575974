@import "../../../../../../../../core/scss/vars";

.topBar {
  margin-left: 86px;
  height: 103px;
  display: flex;
  align-items: center;
}

.line {
  width: 2px;
  height: 100%;
  margin-left: 30px;
  background-color: $purple_20;
}

.text {
  display: flex;
  flex-direction: column;
  margin-left: 35px;

  .description:hover {
    .descriptionText {
      color: $purple-2 !important;
    }
  }
}

.button {
  margin-left: auto;
  margin-right: 150px;
}

.userNav {
  right: 30px;
  z-index: 9999;
}