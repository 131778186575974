
.description {
  margin-top: 50px;
  max-width: 490px;
  align-self: center;
  text-align: center;
}

.navigation {
  display: flex;
  justify-content: center;
  margin-top: 50px;

  &Item {
    width: 155px;

    &:nth-child(n+2) {
      margin-left: 25px;
    }
  }
}