@import "../../../scss/vars";

.input {
  background-color: $black-light-2;
  border: 3px solid $gray-1;
  border-radius: 20px;
  outline: 0;

  font-family: $f-medium;
  font-size: $fs-18;
  color: $white;

  padding: 15px 16px;

  transition: all .15s;

  &:focus {
    border-color: $purple;

    &:hover {
      border-color: $purple;
    }
  }

  &:hover {
    border-color: $white;
  }
}


.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;

  .image {
    top: 22px;
    left: 19px;
    position: absolute;
    width: 22px;
    height: 22px;

    & + .input {
      padding-left: 50px;
    }
  }
}