@import "../../core/scss/vars";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 100px 100px;
}

.block {
  max-width: 1160px;
  width: 100%;

  &:nth-child(n+2) {
    margin-top: 120px;
  }
}