@import "../../../../scss/vars";

.wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  padding: 12px 20px;
  color: $white;
  font-family: $f-medium;
  font-size: $fs-16;

  transition: all 0.2s ease-out;
  cursor: pointer;
  overflow: hidden;

  .title {
    white-space: nowrap;
  }

  &:hover {
    background-color: $black-light-4 !important;
  }
}