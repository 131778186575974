@import "../../../../core/scss/vars";

.wrapper {
  position: relative;
  margin: 0 auto;
  width: 1000px;
  height: 100%;
}

.editor {
  //border: 1px solid $gray-2;
}