.block {
  margin: 65px auto 100px;
  padding: 0 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;


  .button {
    max-width: 440px;
    white-space: nowrap;
    padding: 24px 135px;
  }

  .wrapper {
    width: 100%;
    margin-top: 50px;
    max-width: 1220px;

    .search {
      margin: 0 auto;
    }

    .courses {
      margin-top: 45px;
    }
  }
}