@import "../../../../scss/vars";

.wrapper {
  padding: 45px 45px 80px;

  .description {
    margin-bottom: 20px;
  }

  .courses {
    margin-top: 45px;
  }
}