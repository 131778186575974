@import "../../../../../../core/scss/vars";

.search {
  max-width: 650px;
  width: 100%;
  border-radius: 50px;
  display: flex;
  background-color: $black-light-3;
}

.lineV {
  background-color: $black-light-4;
  border-radius: 50%;
  height: 41px;
  width: 2px;
  margin: auto 0;
}

.buttonSearch {
  margin: 5px;
}