@font-face {
  font-family: "Gotham-Black";
  src: url("core/static/font/Gotham/Gotham-Black.ttf");
  font-display: swap;
}
@font-face {
  font-family: "Gotham-Bold";
  src: url("core/static/font/Gotham/Gotham-Bold.ttf");
  font-display: swap;
}
@font-face {
  font-family: "Gotham-Medium";
  src: url("core/static/font/Gotham/Gotham-Medium.ttf");
  font-display: swap;
}
@font-face {
  font-family: "Gotham-MediumItalic";
  src: url("core/static/font/Gotham/Gotham-MediumItalic.ttf");
  font-display: swap;
}
@font-face {
  font-family: "Gotham-Book";
  src: url("core/static/font/Gotham/Gotham-Book.ttf");
  font-display: swap;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

::placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: "Gotham-Medium", sans-serif !important;
  font-weight: 400;
  color: #969696 !important;
}

/* ВЫДЕЛЕНИЕ ТЕКСТА */
::selection {
  background: #6E51FF; /* Safari */
  color: #FFF;
}
::-moz-selection {
  background: #6E51FF; /* Firefox */
  color: #FFF;
}

/* SCROLL BAR */
::-webkit-scrollbar {
  width: 10px;
  background-color: #f9f9fd;
}

::-webkit-scrollbar:hover {
  /*width: 10px;*/
}

::-webkit-scrollbar-track {
  background-color: #1A1A1A;
}

::-webkit-scrollbar-thumb {
  background: #404040;
}