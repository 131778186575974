@import "../../../../../../../../../core/scss/vars";

.wrapper {
  padding: 50px 30px;

  .cross {
    top: 60px;
    right: 30px;
  }
}

.content {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &Item {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    &:nth-child(n+2) {
      margin-top: 20px;
    }

    &Title {
      text-align: center;
    }
  }
}

.preview {
  max-width: 245px;

  &Image {
    border-radius: 30px;
    width: 100%;
    height: 245px;
  }
}

.input {
  border: 2px solid $gray-1;
  margin-top: 10px;
  width: 100%;
}

.label {
  justify-content: center;
}

.title {
  text-align: center;
  font-family: $f-bold;
  font-size: $fs-20;
}

.navigation {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;

  &Item {
    padding: 12px 30px;

    &:nth-child(n+2) {
      margin-left: 20px;
    }
  }
}